import "vite/modulepreload-polyfill";
import "@unocss/reset/sanitize/sanitize.css";

import "virtual:uno.css";
import "uno.css";
import "@/sections/swiper-wrap";

// import "@/sections/announcement-bar";
// import "@/sections/header-menu/index";
// import "@/sections/banner-desktop";
// import "@/sections/banner-mobile";
import "@/sections/video-promotion";
// import "@/sections/treatment-section";
// import "@/sections/video-column";
// import "@/sections/collection-list";
// import "@/sections/footer-mobile-menu";
// import "@/sections/marquee.jsx";
// import "@/sections/search-panel";

import { register as swiperRegister } from "swiper/element/bundle";

swiperRegister();
