import register from "preact-custom-element";
import c from "classnames";
import { useEffect } from "preact/hooks";
import { setSwiperPadding } from "@/utils/screen";
import { createRef } from "preact";

function SwiperWrap(props) {
  const swiperWrapRef = createRef();
  useEffect(() => {
    const container = swiperWrapRef.current.querySelector("swiper-container");
    setSwiperPadding(
      container,
      Number(props.mobile_space),
      Number(props.desktop_space),
      true,
    );
    window.addEventListener("resize", () => {
      setSwiperPadding();
    });
  }, []);

  return (
    <div ref={swiperWrapRef} className={c("flex w-full flex-col items-center")}>
      {props.content}
    </div>
  );
}

register(SwiperWrap, "swiper-wrap");
