import register from "preact-custom-element";
import c from "classnames";
import { useEffect } from "preact/hooks";
// import { setSwiperPadding } from "@/utils/screen";
import { parseLiquidJSON } from "@/utils/liquidJSON";
import { createRef } from "preact";

function VideoPromotion(props) {
  const settings = parseLiquidJSON(props.settings);

  const videoPromotionRef = createRef();

  let intervalHolder = null;

  useEffect(() => {
    // const container =
    //   videoPromotionRef.current.querySelector("swiper-container");
    // setSwiperPadding(container, 16, 16);
    // window.addEventListener("resize", () => {
    //   setSwiperPadding(container, 16, 16);
    // });
    //
    // queueMicrotask(() => (container.style.opacity = 1));

    const allPlayBtn = videoPromotionRef.current.querySelectorAll("[play-btn]");
    const allVideo = videoPromotionRef.current.querySelectorAll("video");

    allPlayBtn.forEach((item) => {
      item.addEventListener("click", (event) => {
        clearInterval(intervalHolder);
        intervalHolder = false;

        if (item.querySelector("[icon-play]").classList.contains("hidden")) {
          event.target.parentNode.querySelector("video").pause();
          item.querySelector("[icon-play]").classList.remove("hidden");
          item.querySelector("[icon-pause]").classList.add("hidden");
        } else {
          allVideo.forEach((item) => {
            item.pause();
            item.muted = true;
          });
          allPlayBtn.forEach((item) => {
            item.querySelector("[icon-play]").classList.remove("hidden");
            item.querySelector("[icon-pause]").classList.add("hidden");
          });

          event.target.parentNode.querySelector("video").muted = false;
          event.target.parentNode.querySelector("video").play();
          item.querySelector("[icon-play]").classList.add("hidden");
          item.querySelector("[icon-pause]").classList.remove("hidden");
        }
      });
    });

    intervalHolder = setInterval(() => {
      allVideo.forEach((item) => {
        item.currentTime = 0;
      });
    }, 5000);
  }, []);

  return (
    <div
      ref={videoPromotionRef}
      className={c(
        "flex w-full flex-col items-center py-20 gap-28 md:gap-40 md:py-30",
      )}
    >
      <div className={c("flex flex-col gap-8 md:gap-17")}>
        <div
          className={c(
            "ctn [&>span]:underline text-center",
            `text-${settings.m_title_font_size} md:text-${settings.title_font_size}`,
            `lh-${settings.m_title_line_height} md:lh-${settings.title_line_height}`,
            `font-${settings.title_font_weight}`,
          )}
          dangerouslySetInnerHTML={{ __html: settings.title }}
        ></div>

        <div
          className={c(
            "ctn [&>span]:underline text-center",
            `text-${settings.m_subtitle_font_size} md:text-${settings.subtitle_font_size}`,
            `lh-${settings.m_subtitle_line_height} md:lh-${settings.subtitle_line_height}`,
            `font-${settings.subtitle_font_weight}`,
          )}
          dangerouslySetInnerHTML={{ __html: settings.subtitle }}
        ></div>
      </div>

      {props.items}
    </div>
  );
}

register(VideoPromotion, "video-promotion");
