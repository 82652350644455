export const screenPadding = 20;

export const screenSizes = {
  sm: 576,
  xs: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export function isMobile() {
  return window.innerWidth < screenSizes.md;
}

export function setSwiperPadding(
  container,
  mobileSpace,
  desktopSpace,
  props = false,
) {
  const { xxl } = screenSizes;
  let padding = 0;
  if (window.innerWidth + screenPadding * 2 <= xxl) {
    padding = screenPadding;
  } else {
    padding = (window.innerWidth - xxl) / 2 + screenPadding;
  }
  if (props) {
    container.slidesOffsetAfter = padding;
    container.slidesOffsetBefore = padding;
  } else {
    container.setAttribute("slides-offset-after", padding);
    container.setAttribute("slides-offset-before", padding);
    container.setAttribute(
      "space-between",
      isMobile() ? mobileSpace : desktopSpace,
    );
  }
}
